<template>
  <div class="nav_box">
    <van-popup
      :close-on-popstate="true"
      :close-on-click-overlay="false"
      v-model="show"
      @click-overlay="overlayClick"
      position="right"
    >
      <div class="nav_router">
        <ul>
          <li>
            <router-link to="/" exact>首页</router-link>
          </li>
          <li>
            <router-link to="/accelerate">加速说明</router-link>
          </li>
          <li>
            <router-link to="/download">软件下载</router-link>
          </li>
          <li>
            <router-link to="/recharge">产品购买</router-link>
          </li>
          <li v-if="login">
            <router-link to="/admin_center">个人中心</router-link>
          </li>
          <li v-if="!login">
            <span>
              <router-link to="/login">登录</router-link>/
              <router-link to="/register">注册</router-link>
            </span>
          </li>
        </ul>
      </div>
    </van-popup>
  </div>
</template>
<script>
export default {
  name: "navigator",
  computed: {
    login() {
      return this.$store.state.login;
    }
  },
  data() {
    return {
      show: this.$store.state.navShow
    };
  },
  watch: {
    "$store.state.navShow": function(val) {
      this.show = val;
    }
  },
  methods: {
    overlayClick() {
      this.$store.dispatch({
        type: "navShow",
        navShow: false
      });
    }
  }
};
</script>
<style  lang="scss" scoped>
.van-popup {
  height: 100%;
  box-sizing: border-box;
  width: 70%;
}
.nav_box {
  .nav_router {
    max-height: 100%;
    padding: 30px 0;
    ul {
      li {
        padding: 30px;
        a {
          font-size: 18px;
          color: #333;
        }
        .router-link-active {
          color: #517cfc;
        }
      }
    }
  }
}
</style>
