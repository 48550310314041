import axios from 'axios';
import { Message } from 'element-ui';

const instance = axios.create({
	baseURL: 'api/',
	headers: {
		'Content-Type': 'application/json',
	},
});
//请求拦截
instance.interceptors.request.use(
	(config) => {
		config.data = JSON.stringify(config.data);
		const token = sessionStorage.getItem('token');
		if (token) {
			config.headers.Authorization = 'Bearer ' + token;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);
instance.interceptors.response.use(
	(res) => {
		const { code, data, message } = res.data;
		if (code == 401) {
			sessionStorage.removeItem('token');
			window.location.href = '/#/login';
			return;
		}
		if (code !== 0) {
			Message({
				message,
				type: 'error',
			});
		}
		return Promise.resolve({ code, data, message });
	},
	(error) => {
		return Promise.reject(error);
	}
);

export default instance;
