import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterGuard from './routerGuard';

Vue.use(VueRouter);

const routes = [
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/Login.vue'),
		meta: { title: '登录' },
	},
	{
		path: '/login/get_back_pwd',
		name: 'BackPassword',
		component: () => import('../views/BackPassword.vue'),
		meta: { title: '找回密码' },
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('../views/Register.vue'),
		meta: { title: '注册' },
	},
	{
		path: '/',
		name: 'home',
		component: () => import('../views/Home/Index.vue'),
		meta: { title: '首页' },
	},
	{
		path: '/recharge',
		name: 'Recharge',
		component: () => import('../views/Recharge.vue'),
		meta: { title: '产品购买' },
	},

	{
		path: '/accelerate',
		name: 'accelerate',
		component: () => import('../views/Accelerate.vue'),
		meta: { title: '加速' },
	},
	{
		path: '/download',
		name: 'download',
		component: () => import('../views/Home/Install.vue'),
		meta: { title: '下载' },
	},
	{
		path: '/admin_center',
		component: () => import('../views/AdminCenter/index.vue'),
		meta: { title: '个人中心' },
	},

	{
		path: '/preview',
		component: () => import('../views/AdminCenter/Preview.vue'),
		meta: { title: '我的产品' },
	},
	{
		path: '/myorder',
		component: () => import('../views/AdminCenter/MyOrder.vue'),
		meta: { title: '我的订单' },
	},
	{
		path: '/mermber_recharge',
		component: () => import('../views/AdminCenter/MemberRecharge.vue'),
		meta: { title: '会员充值' },
	},
	{
		path: '/address',
		component: () => import('../views/AdminCenter/Address.vue'),
		meta: { title: '配送地址' },
	},
	{
		path: '/certification',
		component: () => import('../views/AdminCenter/Certification.vue'),
		meta: { title: '实名认证' },
	},
	{
		path: '/email_bind',
		component: () => import('../views/AdminCenter/EmailBind.vue'),
		meta: { title: '邮箱绑定' },
	},
	{
		path: '/change_pwd',
		component: () => import('../views/AdminCenter/ChangePwd.vue'),
		meta: { title: '修改密码' },
	},
	{
		path: '/record',
		component: () => import('../views/AdminCenter/Record.vue'),
		meta: { title: '分销记录' },
	},
	{
		path: '/share',
		component: () => import('../views/AdminCenter/Share.vue'),
		meta: { title: '邀请好友' },
	},
	{
		path: '/wiki',
		component: () => import('../views/AdminCenter/Wiki.vue'),
		meta: { title: 'Wiki' },
	},
	{
		path: '/privacy_policy',
		component: () => import('../views/Information/PrivacyPolicy.vue'),
		meta: { title: '隐私协议' },
	},
	{
		path: '/consumer_terms',
		component: () => import('../views/Information/ConsumerTerms.vue'),
		meta: { title: '服务条款' },
	},
];

const router = new VueRouter({
	routes,
});
RouterGuard(router);

export default router;
