<template>
  <div id="app">
    <img @click="ChangenavShow" class="nav" src="./assets/nav.png" alt />
    <Nav />
    <router-view />
    <!-- <Footer /> -->
  </div>
</template>
<script>
import Nav from "@/components/Nav";
export default {
  name: "App",
  components: {
    Nav
  },
  methods: {
    ChangenavShow() {
      this.$store.dispatch({
        type: "navShow",
        navShow: true
      });
    }
  },
  mounted() {
    document.getElementById('app').style.minHeight = window.screen.availHeight  +'px';
  }
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  position: relative;
}
.nav {
  width: 24px;
  z-index: 10;
  position: fixed;
  top: 30px;
  right: 5%;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
